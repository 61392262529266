import UserService from "../../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../../api/ApiUtils";


const userService = new UserService();

export default class DocumentService{


    createDocument(documentInputDto){

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                documentInputDto
            )
        };

        return fetch('/api/v1/documents', requestOptions)
            .then(checkStatus)
            .then(response => response.json())
    }

    updateDocumentNotes(documentId, updateNotesInputDto){

        const apiKey = userService.getApiKey();


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(
                updateNotesInputDto
            )
        };

        return fetch(`/api/v1/documents/${documentId}/notes`, requestOptions)
            .then(checkStatus)
            // .then(response => response.json())

    }

    getDocumentsByFilters(filters){

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/documents`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCSV(documentId){
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/documents/${documentId}/csv`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    deleteDocument(documentId){
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/documents/${documentId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    addToQueue(documentId, body){
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(body)
        };
        let pathVariable = `/api/v1/documents/${documentId}/add-to-queue`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    retransmitDocument(documentId){
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/documents/${documentId}/retransmit`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    previousMonth(){
        const apiKey = userService.getApiKey();

        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        let pathVariable = `/api/v1/documents/previousmonth`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }




}