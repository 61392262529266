import React, {useEffect, useState} from "react";
import {Button, Menu, MenuItem, TableCell, TableRow} from "@material-ui/core";
import {Card, CircularProgress, Dialog, IconButton, TextField, Typography} from "@mui/material";
import {Circle, Download} from "@mui/icons-material";
import {Delete, NoteAddOutlined, NoteOutlined, Replay, Send} from "@material-ui/icons";
import DocumentService from "../documents/DocumentService";
import Notification from "../../components/notifications/Notification";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../../components/dialogs/ConfirmDialog";
import {formatDate} from "../../utils/utils";
import NoteTooltip from "../../components/NoteTooltip";
import MenuIcon from "@material-ui/icons/Menu";


function CustomerPreInvoice(props) {

    const preInvoice = props.preInvoice;

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [confirmRetransmissionDialogOpen, setConfirmRetransmissionDialogOpen] = useState(false);
    const [addPurchaseOrderDialogOpen, setAddPurchaseOrderDialogOpen] = useState(false);
    const [updateNote, setUpdateNote] = useState(false);

    const [purchaseOrder, setPurchaseOrder] = useState(null);

    const [loadingCSV, setLoadingCSV] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [notes, setNotes] = useState(preInvoice.notes);


    const {t} = useTranslation();

    function handlePurchaseOrderChange(e) {
        setPurchaseOrder(e.target.value)
    }

    function handleNotesChange(e) {
        setNotes(e.target.value);
    }

    async function confirmUpdateNotes() {

        let input = {notes: notes};

        await new DocumentService().updateDocumentNotes(preInvoice.documentId, input)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setUpdateNote(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setUpdateNote(false);
                })
            })


    }

    async function downloadCSV() {

        setLoadingCSV(true);

        await new DocumentService().getCSV(props.preInvoice.documentId)
            .then(response => {
                let tempLink = document.createElement('a');
                tempLink.href = `data:${response.contentType};base64,` + response.content;
                tempLink.setAttribute('download', response.fileName);
                tempLink.click();

                setLoadingCSV(false);
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setLoadingCSV(false);
                })
            });
    }

    async function deleteDocument() {

        await new DocumentService().deleteDocument(props.preInvoice.documentId)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setConfirmDeleteDialogOpen(false);
                props.deleteCallback();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function transmitDocument() {

        let body = {};

        if (purchaseOrder) {
            body.purchaseOrder = purchaseOrder;
        }

        await new DocumentService().addToQueue(props.preInvoice.documentId, body)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.refresh();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }

    async function retransmitDocument() {

        await new DocumentService().retransmitDocument(props.preInvoice.documentId)
            .then(response => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                props.refresh();
            })
            .catch((err) => {
                err.response.json().then(response => {
                    setNotificationMessage(response.status + "-" + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                })
            });
    }


    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    return <React.Fragment>
        <TableRow>
            <TableCell component="th" scope="row">
                {preInvoice.documentNumber}
            </TableCell>
            {/*<TableCell align="left">{preInvoice.contractCode} </TableCell>*/}
            <TableCell align="left">{preInvoice.contractName}</TableCell>
            <TableCell align="left">{formatDate(preInvoice.date)}</TableCell>
            <TableCell align="left">{preInvoice.totalAmount.toFixed(2)}</TableCell>
            <TableCell align="left">{preInvoice.purchaseOrder}</TableCell>
            <TableCell align="left">{preInvoice.invoiceNumber}</TableCell>
            <TableCell align="left">{formatDate(preInvoice.invoiceDate)}</TableCell>
            <TableCell align="left">{preInvoice.notes ?
                <NoteTooltip>{preInvoice.notes}</NoteTooltip> : null}</TableCell>
            <TableCell align="left"><Circle
                style={{color: preInvoice.transmitted ? "green" : (preInvoice.inQueue ? "gold" : "grey")}}/></TableCell>
            <TableCell align="left">
                <Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>
                    <MenuIcon/>
                </Button>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem disabled={loadingCSV} onClick={downloadCSV}>{loadingCSV ?
                        <CircularProgress size={24}/>
                        :
                        <Download/>
                    }&nbsp;{t("download_csv")}</MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setConfirmDeleteDialogOpen(true);
                    }}><Delete/>&nbsp;{t("delete")}</MenuItem>
                    <MenuItem disabled={props.preInvoice.transmitted || props.preInvoice.inQueue} onClick={() => {
                        setAnchorEl(null);
                        setAddPurchaseOrderDialogOpen(true)
                    }}><Send/>&nbsp;{t("send")}</MenuItem>
                    <MenuItem disabled={props.preInvoice.transmitted || props.preInvoice.inQueue} onClick={() => {
                        setAnchorEl(null);
                        setConfirmRetransmissionDialogOpen(true)
                    }}><Replay/>&nbsp;{t("retransmit")}</MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setUpdateNote(true)
                    }}><NoteAddOutlined/>&nbsp;{t("add_modify_note")}</MenuItem>

                </Menu>
            </TableCell>
        </TableRow>
        <ConfirmDialog open={confirmDeleteDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={deleteDocument}
                       onCancel={() => setConfirmDeleteDialogOpen(false)}>
            {t("confirm_delete")}
        </ConfirmDialog>
        <ConfirmDialog open={addPurchaseOrderDialogOpen}
                       title={"Aggiungi eventuale ordine d'acquisto"}
                       confirmText={t("send")}
                       cancelText={t("cancel")}
                       onConfirm={transmitDocument}
                       onCancel={() => setAddPurchaseOrderDialogOpen(false)}>
            <TextField
                label={t("purchase_order")}
                size={"small"}
                variant="outlined"
                margin="dense"
                value={purchaseOrder}
                defaultValue={purchaseOrder}
                onChange={handlePurchaseOrderChange}
            />
        </ConfirmDialog>
        <ConfirmDialog open={updateNote}
                       title={t("add_modify_note")}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={confirmUpdateNotes}
                       onCancel={() => setUpdateNote(false)}>
            <TextField
                label={t("notes")}
                size={"small"}
                multiline
                variant="outlined"
                margin="dense"
                value={notes}
                defaultValue={notes}
                onChange={handleNotesChange}
            />
        </ConfirmDialog>
        <ConfirmDialog open={confirmRetransmissionDialogOpen}
                       title={"ATTENZIONE!"}
                       confirmText={t("confirm")}
                       cancelText={t("cancel")}
                       onConfirm={retransmitDocument}
                       onCancel={() => setConfirmRetransmissionDialogOpen(false)}>
            {t("confirm_retransmit")}
        </ConfirmDialog>
        <Notification open={openNotify} severity={notifySeverity} duration={3000}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
    </React.Fragment>
}

export default CustomerPreInvoice;