import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Button,
    Checkbox,
    DialogTitle,
    FormControlLabel,
    Paper, Radio, RadioGroup,
    Table,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Notification from "../../components/notifications/Notification";
import CustomerPreInvoiceService from "./CustomerPreInvoiceService";
import {compareAsc} from "date-fns";
import AccountService from "../../accounts/AccountService";
import ContractService from "../../accounts/contracts/ContractService";
import {Autocomplete} from "@mui/material";
import DateField from "../../components/fields/DateField";
import {ArrowBackRounded, SearchOutlined} from "@material-ui/icons";
import moment from "moment";
import AccountingRowService from "../AccountingRowService";
import {Card, CircularProgress, IconButton, TableBody, TableCell, TableHead} from "@mui/material";
import theme from "../../theme/theme";
import InfoChip from "../../components/chips/InfoChip";
import DocumentService from "../documents/DocumentService";
import WarningChip from "../../components/chips/WarningChip";
import AmountChip from "../../components/chips/AmountChip";
import SubContractService from "../../accounts/contracts/sub-contracts/SubContractService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textAlign: 'center',
        justifyContent: "center",
        display: "block",

    },
    field: {
        display: "flex",
        margin: "auto",
        marginBottom: "1%",
        marginTop: "1%",
        width: '25%',
    },
    label: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        width: "30%",

    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: "3%",
        marginBottom: "3%",
        color: theme.palette.primary.main
    },
    filterMask: {
        width: "80%",
        margin: "auto",
        marginBottom: "3%",
        // border: "2px solid"
    },

}));

function CustomerPreInvoiceForm(props) {

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [progressive, setProgressive] = useState('');
    const [preInvoiceDate, setPreInvoiceDate] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [contract, setContract] = useState(null);
    const [contracts, setContracts] = useState([]);
    const [subContracts, setSubContracts] = useState([]);
    const [subContract, setSubContract] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [accountingRows, setAccountingRows] = useState([])
    const [allChecked, setAllChecked] = useState(true);
    const [type, setType] = useState("EARN")
    const [excludeReleases, setExcludeReleases] = useState(false);
    const [excludeReleased, setExcludeReleased] = useState(false);
    const [hasPod, setHasPod] = useState(false);

    const [valueSign, setValueSign] = useState("ANY");

    const [shipmentFilter, setShipmentFilter] = useState('');
    const [pickupFilter, setPickupFilter] = useState('');
    const [ledgerAccountFilter, setLedgerAccountFilter] = useState('');

    const [noProgressiveValidForDate, setNoProgressiveValidForDate] = useState(false);

    const [notes, setNotes] = useState(null);

    const [openNotify, setOpenNotify] = useState(false);
    const [notifySeverity, setNotifySeverity] = useState('');
    const [notificationMessage, setNotificationMessage] = useState(null);

    const location = useLocation();
    const {t} = useTranslation();
    const history = useHistory();


    const classes = useStyles();

    function handleValueSignChange(event){
        setValueSign(event.target.value);
    }


    async function fetchCustomers() {

        let filters = {};
        filters.isCustomer = true;

        let customers = await new AccountService().getAccounts(filters);
        setCustomers(customers)
    }

    async function fetchContracts() {
        let filters = {}
        filters.accountId = customer.id;

        let contracts = await new ContractService().getContractsByFilters(filters)
        setContracts(contracts)
    }

    function fetchSubContracts() {
        let subContractService = new SubContractService();
        let filters = {}
        if (contract) {
            filters.contractId = contract.id;
        }
        subContractService.getSubContractsByFilters(filters)
            .then(subContracts => {
                setSubContracts(subContracts);
            })
            .catch(error => {
            })
    }

    function handleFirstDate(event) {
        if (toDate == null || compareAsc(event, toDate) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setFromDate(event)
        }
    }

    function handleSecondDate(event) {
        if (fromDate == null || compareAsc(fromDate, event) === 1) {
            setFromDate(event);
            setToDate(event);
        } else {
            setToDate(event);
        }
    }

    function handleCustomerChange(event, customer) {
        setCustomer(customer);
        setContract(null);
    }


    function handleLedgerAccountFilterChange(event){
        setLedgerAccountFilter(event.target.value);
    }

    function handleShipmentFilterChange(event){
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setShipmentFilter(amount);
        }
    }


    function handlePickupFilterChange(event) {
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setPickupFilter(amount);
        }
    }

    function handleAllChecked(event) {
        setAllChecked(event.target.checked);

        let accRows = accountingRows
            .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row);


        for (let i = 0; i < accRows.length; i++) {
            accRows[i].checked = !!event.target.checked;
        }

    }

    function handleChecked(event, key) {

        accountingRows
            .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
            .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
            .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            [key].checked = event.target.checked;

        if (accountingRows.some(accountingRow => accountingRow.checked === false)) {
            setAllChecked(false);
        } else {
            setAllChecked(true);
        }

        setRefresh(!refresh);

    }

    function handleNotesChange(event){
        setNotes(event.target.value);
    }


    async function search() {

        if(!customer || !contract){
            setNotificationMessage(t("void_required_fields"));
            setNotifySeverity('error');
            setOpenNotify(true);
        } else {
            setLoading(true);

            let filters = {};

            filters.hasDocument = false;

            filters.accountId = customer.id;

            filters.contractId = contract.id;

            if(subContract){
                filters.subContractId = subContract.id;
            }

            if(excludeReleases){
                filters.excludeReleases = true;
            }

            if(excludeReleased){
                filters.excludeReleased = true;
            }

            if(hasPod){
                filters.hasPod = true;
            }

            if(valueSign !== "ANY"){
                filters.valueSign = valueSign;
            }

            filters.type = type;

            if (null != fromDate) {
                filters.fromDate = moment(fromDate).format('yyyy-MM-DD');
            }
            if (null != toDate) {
                filters.toDate = moment(toDate).format('yyyy-MM-DD');
            }

            let accRows = await new AccountingRowService().getAccountingRows(filters)
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.status + " - " + response.message);
                        setNotifySeverity('error');
                        setOpenNotify(true);
                        setRowsPerPage(5);
                        setPage(0);
                        setRefresh(!refresh);
                        setLoading(false);
                    })
                })

            if (accRows) {
                let data = [];

                for (let i = 0; i < accRows.length; i++) {
                    let accountingRowsData = {};
                    accountingRowsData.checked = true;
                    accountingRowsData.accountingRow = accRows[i];

                    data.push(accountingRowsData);
                }

                setAllChecked(true);

                setAccountingRows(data);
                setLoading(false);
                setRefresh(!refresh);
            }
        }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function handleProgressiveChange(event) {
        let amount = event.target.value;

        if (!(isNaN(amount) || amount < 0 || event.target.value[0] === '0')) {
            setProgressive(amount);
        }

    }

    function createPreInvoice(event) {
        event.preventDefault();

        setLoadingSubmit(true);

        let document = {};

        document.date = moment(preInvoiceDate).format('yyyy-MM-DD');
        if(progressive !== ''){
            document.number = progressive;
        }
        document.contractId = contract.id;
        document.type = "ACTIVE_PREINVOICE";
        document.accountingRowIds = [];
        if(notes){
            document.notes = notes;
        }

        for(let i = 0; i<accountingRows.length; i++){
            if(accountingRows[i].checked){
                document.accountingRowIds.push(accountingRows[i].accountingRow.id);
            }
        }

        new DocumentService().createDocument(document)
            .then(result => {
                setNotificationMessage(t("successful"));
                setNotifySeverity('success');
                setOpenNotify(true);
                setPreInvoiceDate(null);
                setProgressive('');
                search();
                setRefresh(!refresh);
                setLoadingSubmit(false);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.status + " - " + response.message);
                    setNotifySeverity('error');
                    setOpenNotify(true);
                    setRefresh(!refresh);
                    setLoadingSubmit(false);
                })
            })




    }

    function handlePreInvoiceDateChange(event){
        setPreInvoiceDate(event);

        if(event != null){
            getProgressive(event);
        } else {
            setProgressive('');
            setNoProgressiveValidForDate(false);
        }

    }

    async function getProgressive(date) {
        let filters = {};

        filters.date = moment(date).format('yyyy-MM-DD');
        filters.type = "ACTIVE_PREINVOICE";
        await new CustomerPreInvoiceService().getProgressive(filters)
            .then(response => {
                setNoProgressiveValidForDate(false);
                setProgressive(response.id);
            })
            .catch(error => {
                setNoProgressiveValidForDate(true);
                setProgressive('');
            })

    }

    function calculateTotal(data) {

        let total = 0;

        for (let i = 0; i < data.length; i++) {

            if (data[i].checked === true) {
                if (data[i].accountingRow.type === "COST") {
                    total -= data[i].accountingRow.amount;
                }
                if (data[i].accountingRow.type === "EARN") {
                    total += data[i].accountingRow.amount;

                }

            }
        }

        return total;
    }

    function goBack() {
        history.push('/customer-pre-invoices');
    }

    useEffect(() => {


    }, [refresh, location, page, rowsPerPage])

    return <Paper className={classes.root}>
        <Notification open={openNotify} severity={notifySeverity} duration={3500}
                      onClose={() => setOpenNotify(false)}>{notificationMessage}</Notification>
        <div style={{display: "flex"}}>
            <Button
                style={{margin: "2%", background: "transparent"}}
                onClick={goBack}
                startIcon={<ArrowBackRounded/>}
                variant={"filled"}
            >
                {t("back")}
            </Button>

        </div>
        <DialogTitle id="form-dialog-title">{t("pre-invoice")}</DialogTitle>
        <Card elevation={2} className={classes.filterMask}>
            <Autocomplete
                id="customer"
                style={{width: "30%", margin: "auto", marginBlock: "2%"}}
                options={customers}
                getOptionLabel={option => option.companyName}
                value={customer}
                defaultValue={customer}
                noOptionsText={t("no_options")}
                onOpen={fetchCustomers}
                onChange={handleCustomerChange}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("customer")}
                        variant="outlined"
                        size={"small"}
                        margin="dense"
                        value={customer}
                        defaultValue={customer}
                        required
                        error={!customer}
                    />
                )}
            />
            <Autocomplete
                id="contract"
                size="small"
                style={{width: "30%", margin: "auto", marginBottom: "2%"}}
                options={contracts}
                disabled={!customer}
                getOptionLabel={option => option.name}
                value={contract}
                defaultValue={contract}
                noOptionsText={t("no_options")}
                onOpen={fetchContracts}
                onChange={(event, contract) => {setContract(contract); setSubContract(null); setSubContracts([])}}
                ListboxProps={{style: {maxHeight: '15rem'},}}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("contract")}
                        variant="outlined"
                        margin="dense"
                        value={contract}
                        defaultValue={contract}
                        required
                        error={!contract}
                    />
                )}
            />
            <Autocomplete
                id="subContract"
                size="small"
                disabled={!contract}
                style={{width: "30%", margin: "auto", marginBottom: "2%"}}
                options={subContracts}
                getOptionLabel={option => option.code}
                noOptionsText={t("no_sub-contracts_for_contract")}
                value={subContract}
                defaultValue={subContract}
                onChange={(event, subContract) => setSubContract(subContract)}
                onOpen={fetchSubContracts}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t("sub-contract")}
                        variant="outlined"
                        margin="dense"
                        value={subContract}
                        defaultValue={subContract}
                        helperText={!contract ? t("select_contract_first") : null}
                    />
                )}
            />
            <div style={{display: "flex"}}>
                <DateField
                    style={{margin: "auto", width: "20%"}}
                    label={t("management_from_date")}
                    value={fromDate}
                    onChange={handleFirstDate}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                />
                <DateField
                    style={{margin: "auto", width: "20%"}}
                    label={t("management_to_date")}
                    value={toDate}
                    onChange={handleSecondDate}
                    format={"dd/MM/yyyy"}
                    disableFuture={true}
                />
            </div>
            <div style={{display: "block", margin: "auto"}}>
                <FormControlLabel
                    style={{marginBlock: "1%"}}
                    label={<Typography fontSize={12}
                                       variant={"subtitle2"}>{t("exclude_released_shipments")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={excludeReleased}
                            onChange={event => setExcludeReleased(event.target.checked)}
                        />
                    }
                    labelPlacement={"right"}
                />
            </div>
            <div style={{display: "block", margin: "auto"}}>
                <FormControlLabel
                    style={{marginBlock: "1%"}}
                    label={<Typography fontSize={12}
                                       variant={"subtitle2"}>{t("exclude_releases_shipments")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={excludeReleases}
                            onChange={event => setExcludeReleases(event.target.checked)}
                        />
                    }
                    labelPlacement={"right"}
                />
            </div>
            <div style={{display: "block", margin: "auto"}}>
                <FormControlLabel
                    style={{marginBlock: "1%"}}
                    label={<Typography fontSize={12}
                                       variant={"subtitle2"}>{t("include_with_pod_only")}</Typography>}
                    control={
                        <Checkbox
                            style={{color: theme.palette.primary.main}}
                            checked={hasPod}
                            onChange={event => setHasPod(event.target.checked)}
                        />
                    }
                    labelPlacement={"right"}
                />
            </div>
            <div style={{display: "block", margin: "auto", marginBlock: "2%"}}>
                <Typography variant={"subtitle2"}>{t("value_sign")}</Typography>
                <RadioGroup
                    row
                    value={valueSign}
                    onChange={handleValueSignChange}
                    style={{margin: "auto", width: "fit-content"}}
                >
                    <FormControlLabel value={"POSITIVE"} control={<Radio color={"primary"}/>}
                                      label={t("positive_values_only")}/>
                    <FormControlLabel value={"NEGATIVE"} control={<Radio color={"primary"}/>}
                                      label={t("negative_values_only")}/>
                    <FormControlLabel value={"ANY"} control={<Radio color={"primary"}/>}
                                      label={t("any")}/>
                </RadioGroup>
            </div>

            <IconButton onClick={search}
                        disabled={loading}
                        style={{backgroundColor: 'transparent', marginBottom: "2%"}}>{loading ?
                <CircularProgress size={24} style={{color: theme.palette.primary.main}}/> :
                <SearchOutlined style={{color: theme.palette.primary.main}}/>}</IconButton>

        </Card>
        {accountingRows.length > 0 ?
            <div>
                <DialogTitle><Typography variant={"button"}>{t("pre_invoice_data")}</Typography></DialogTitle>
                <div style={{display: "flex", width: "80%", margin: "auto"}}>
                    <DateField
                        style={{margin: "auto", width: "15%"}}
                        label={t("date")}
                        value={preInvoiceDate}
                        onChange={handlePreInvoiceDateChange}
                        format={"dd/MM/yyyy"}
                        disableFuture={true}
                    />
                    <AmountChip color={calculateTotal(accountingRows) >= 0 ? "green" : "red"}>
                        <div style={{display: "flex"}}>
                            <Typography variant={"body2"} style={{
                                display: "flex",
                                fontWeight: "bold"
                            }}>{t("total")} {":"}
                            </Typography >
                            <Typography variant={"body2"} style={{
                                fontWeight: "bold",
                                color: calculateTotal(accountingRows) >= 0 ? "green" : "red"
                            }}>{calculateTotal(accountingRows).toFixed(2) + " " + accountingRows[0].accountingRow.currencyCode}
                            </Typography>
                        </div>
                    </AmountChip>
                    <TextField
                        style={{width: "15%", margin: "auto"}}
                        label={t("progressive")}
                        variant="outlined"
                        margin="dense"
                        disabled={noProgressiveValidForDate}
                        value={progressive}
                        defaultValue={progressive}
                        onChange={handleProgressiveChange}
                        required
                        error={!progressive}
                    />
                </div>
                <div>
                    <WarningChip display={noProgressiveValidForDate? null : "none"}>{t("no_valid_progressive_for_date")}</WarningChip>
                </div>
                <Table stickyHeader style={{width: "80%", margin: "auto"}}>

                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox color="primary"
                                          onChange={handleAllChecked}
                                          checked={allChecked}/>
                            </TableCell>
                            <TableCell>
                                {t("date")}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("ledger_account")}
                                    value={ledgerAccountFilter}
                                    defaultValue={ledgerAccountFilter}
                                    onChange={handleLedgerAccountFilterChange}
                                />
                            </TableCell>
                            <TableCell>
                                {t("description")}
                            </TableCell>
                            <TableCell>
                                {t("amount")}
                            </TableCell>
                            <TableCell>
                                {t("currency")}
                            </TableCell>
                            <TableCell>
                                {t("customer")}
                            </TableCell>
                            <TableCell>
                                {t("contract")}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("shipment")}
                                    value={shipmentFilter}
                                    defaultValue={shipmentFilter}
                                    onChange={handleShipmentFilterChange}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant={"outlined"}
                                    size={"small"}
                                    label={t("pickup")}
                                    value={pickupFilter}
                                    defaultValue={pickupFilter}
                                    onChange={handlePickupFilterChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*{(rowsPerPage > 0*/}
                        {/*        ? accountingRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                        {/*        : accountingRows*/}
                        {/*)  */}
                        {accountingRows
                            .filter(row => shipmentFilter ? row.accountingRow.shipmentId?.toString() === shipmentFilter : row)
                            .filter(row => pickupFilter ? row.accountingRow.pickupId?.toString() === pickupFilter : row)
                            .filter(row => ledgerAccountFilter? row.accountingRow.ledgerAccountCode === ledgerAccountFilter.toUpperCase() : row)
                            .slice(rowsPerPage > 0? page * rowsPerPage : 0, rowsPerPage > 0? page * rowsPerPage + rowsPerPage : accountingRows.length)
                            .map((data, key) => {
                            return <TableRow>
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        onChange={event => handleChecked(event, key)}
                                        checked={data.checked}/>
                                </TableCell>
                                <TableCell>
                                    {new Date(data.accountingRow.date).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"overline"} style={{fontWeight: "bold", fontStyle: "italic"}}>{data.accountingRow.ledgerAccountCode}</Typography>
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.description}
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"body2"} style={{fontWeight: "bold", color: data.accountingRow.type === "EARN"? "green" : "red"}}>{data.accountingRow.amount.toFixed(2)}</Typography>
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.currencyCode}
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.accountCorporateCode}
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.contractCode}
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.shipmentId? data.accountingRow.shipmentId : null}
                                </TableCell>
                                <TableCell>
                                    {data.accountingRow.pickupId ? data.accountingRow.pickupId : null}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
                    <TablePagination
                        style={{width: "80%", margin: "auto"}}
                        rowsPerPageOptions={[5, 10, 25, {label: t("all"), value: -1}]}
                        labelRowsPerPage={t("rows_per_page")}
                        labelDisplayedRows={() => {
                            return (t("total_selected") +": " + accountingRows.filter(acc => !!acc.checked)?.length)
                        }}
                        component="div"
                        count={accountingRows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                <div>
                    <TextField
                        style={{width: "70%", margin: "auto", marginTop: "5%"}}
                        label={t("notes")}
                        variant="outlined"
                        margin="dense"
                        value={notes}
                        defaultValue={notes}
                        onChange={handleNotesChange}
                        multiline
                    />
                </div>
                <Button
                    className={classes.button}
                    disabled={accountingRows.length < 1 || loadingSubmit}
                    onClick={createPreInvoice}
                >{loadingSubmit? <CircularProgress style={{color: theme.palette.primary.main}} size={24}/> : t("create_pre_invoice")}
                </Button>
            </div>
            :

            <InfoChip>{t("no_rows")}</InfoChip>}



    </Paper>

}

export default CustomerPreInvoiceForm;