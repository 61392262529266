import fetch from 'unfetch';
import {checkStatus} from "../../api/ApiUtils";
import UserService from "../../users/UserService";

export default class CountryService {


    getCountriesByFilters(pageNumber, pageSize, filters, guestKey) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (null != pageNumber) {
            queryString += `pageNumber=${pageNumber}`;
        }
        if (pageSize) {
            if (queryString.length > 0) {
                queryString += `&`;
            }
            queryString += `pageSize=${pageSize}`;
        }

        if (filters) {

            const keys = Object.keys(filters);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = filters[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json',
            }
        };

        if(guestKey !== null){
            requestOptions.headers['key'] = guestKey;
        }

        let pathVariable = `/api/v1/geo/countries`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getCountryById(countryId) {
        const userService = new UserService();
        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Accept': 'application/json'
            }
        };

        return fetch(`/api/v1/geo/countries/${countryId}`, requestOptions)
            .then(checkStatus)
            .then(response => response.json());

    }
}